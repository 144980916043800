import React from "react";
import styled, { css } from "styled-components";
import { max_md } from "../utils/mediaQuery";

export default ({ heading, children }) => (
  <TableCell as={heading ? "th" : "td"}>{children}</TableCell>
);

const TableCell = styled.td`
  padding: 16px 0 16px 16px;
  text-align: left;
  vertical-align: top;

  &:first-child {
    padding-left: 0;
  }

  ${max_md(css`
    padding: 0;

    &:first-child {
      padding-bottom: 8px;
    }
  `)}
`;
