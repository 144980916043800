import React from "react";
import styled from "styled-components";

export default ({ children }) => (
  <Table>
    <tbody>{children}</tbody>
  </Table>
);

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;
