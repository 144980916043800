import React from "react";
import styled, { css } from "styled-components";
import { max_md } from "../utils/mediaQuery";

export default ({ children }) => <TableRow>{children}</TableRow>;

const TableRow = styled.tr`
  border-top: 1px solid ${({ theme }) => theme.colors.border};
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};

  ${max_md(css`
    display: flex;
    flex-direction: column;
    margin: 0 0 -1px;
    padding: 16px 0;
  `)}
`;
