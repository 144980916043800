import React from "react";
import styled from "styled-components";
import Container from "../components/Container";
import SEO from "../components/SEO";
import PageHeader from "../components/PageHeader";
import Row from "../components/Row";
import Column from "../components/Column";
import Table from "../components/Table";
import TableRow from "../components/TableRow";
import TableCell from "../components/TableCell";

export default () => (
  <>
    <SEO lang="en" title="About SocialDog, Inc." />
    <PageHeader title="About SocialDog, Inc." />
    <Container>
      <Section>
        <Row justifyContent="center">
          <Column col={8}>
            <Table>
              <TableRow>
                <TableCell heading>Company Name</TableCell>
                <TableCell>SocialDog, Inc.</TableCell>
              </TableRow>
              <TableRow>
                <TableCell heading>Location</TableCell>
                <TableCell>
                  B-9F, Ariake Frontier Bldg. 3-7-26, Ariake, Koto-ku Tokyo,
                  135-0063, JAPAN
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell heading>CEO</TableCell>
                <TableCell>Masafumi Konishi</TableCell>
              </TableRow>
              <TableRow>
                <TableCell heading>Business</TableCell>
                <TableCell>
                  Internet services business
                  <br />
                  <a
                    href="https://social-dog.net/en"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    SocialDog
                  </a>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell heading>Capital</TableCell>
                <TableCell>44,770,000 JPY</TableCell>
              </TableRow>
              <TableRow>
                <TableCell heading>Established</TableCell>
                <TableCell>July 25th, 2016</TableCell>
              </TableRow>
              <TableRow>
                <TableCell heading>Employees</TableCell>
                <TableCell>34</TableCell>
              </TableRow>
              <TableRow>
                <TableCell heading>Certification</TableCell>
                <TableCell>
                  Information Security Management System (ISMS) ISO/IEC
                  27001:2022, JIS Q 27001:2023
                  <br />
                  (Scope of registration: Development, operation, and
                  maintenance of Internet services related to SNS operations)
                </TableCell>
              </TableRow>
            </Table>
          </Column>
        </Row>
      </Section>
    </Container>
  </>
);

const Section = styled.section`
  margin: 0 0 80px;
`;
